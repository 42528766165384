.body{
    margin: 0;
    padding: 0;
    height: 100vh;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: #f8f8fa;
}

.reg2-body{
    height: 100vh;
    width: 100vw;
}

.vertical-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
