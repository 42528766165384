.inline-body p {
  font-size: 1.6em;
  color: #606374;
  margin: 0;
  padding: 0;
}

@media (max-width: 600px) {
  .language-picker {
    margin-left: 0;
  }
}

.termsParagraf{
  font-size: 0.9rem;
}

.termsHeader{
  font-size: 1.6rem;
}