.mobile-chosen-container {
    width: 1.8rem;
    background-color: #35b85a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vikariat-chosen-container {
    border-radius: 50%;
    width: 10%;
    background-color: #35b85a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-not-chosen-container {
    width: 1.8rem;
    background-color: #ff6175;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile-check {
    filter: brightness(0) invert(1);
    display: block;
    margin: 0 auto;
    height: 2em;
    width: 1.5em;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.vikariat-check {
    width: 20px;
    height: 20px;
    filter: invert(0.5) sepia(1) saturate(5) hue-rotate(120deg) brightness(0.9);
}

.no-applicants-card {
    border: none;
    background-color: white;
    color: rgb(255, 165, 58);
    font-size: 1.6em;
    font-weight: 600;
    text-align: center;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgb(13 17 77 / 10%);
}

.date-sub-color {
    color: #9192a2;
}

.truncate {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-90 {
    max-width: 90%; /* 25 characters */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-small {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobile-card {
    flex: 1 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgb(13 17 77 / 10%);
    outline: none;
    border: none;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: black !important;
    transition: all 0.2s ease-out;
    height: 10em;
    text-decoration: none !important;
}

.mobile-card a:hover {
    color: black;
}

.inactive-m-card {
    flex: 1 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgb(13 17 77 / 10%);
    outline: none;
    border: none;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: black !important;
    transition: all 0.2s ease-out;
    height: 10em;
    text-decoration: none !important;
    opacity: 0.35;
    /*  z-index: -1; */
}

.icon-wrapper {
    height: 100%;
}

.icon-wrapper .default-icon {
    justify-content: center;
    align-items: center;
    height: calc(50% - 10px) !important;
}

.icon-wrapper .no-bookmark {
    justify-content: center;
    align-items: center;
    height: 100%;
}

.icon-wrapper .bookmark-icon {
    margin-top: 10px;
}

.icon-wrapper .mobile-check {
    margin-left: auto;
    margin-right: auto;
}
