.navbar {
  background-color: #ffffff;
  height: 120px;
  display: flex;
  justify-content: start;
  align-items: left;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  padding-left: 6rem;
}

.version {
  color: #8d9298;
  bottom: 0;
  position: absolute;
  margin-left: 5px;
  cursor: none;
}

.version-middle {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 26px; /* Need a specific value to work */
}

.menu-bars {
  /* margin-left: 2rem; */
  padding: 5px 5px 0px 5px;
  transition: 350ms;
  border-radius: 16px;
  color: #555aa1;
  display: none;
}
.menu-bars:hover {
  color: #555aa1;
}

.activeLink img {
  fill: #555aa1;
}

.bars-active {
  color: #555aa1;
  background-color: #f6f6f7;
  margin-left: 230px;
  display: block;
}

.nav-separator {
  width: 90%;
  height: 1px;
  border: 0px;
  background-color: #f5f5f5;
}

.top-nav-items {
  margin-left: auto;
}

.nav-menu {
  background-color: #ffffff;
  height: 100vh;
  width: 6rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: 850ms;
  box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
  /* display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 100000; */
}

.nav-menu.active {
  left: 0;
  transition: 850ms;
  z-index: 100000;
  display: flex;
  padding-top: 0;
  justify-content: center;
  width: 300px;
  background-color: #ffffff;
}

.page-title {
  color: #2e2f31;
  font-weight: 700;
  font-size: 30px;
  transition: 850ms;
  margin-bottom: 0;
}

.change-user-type {
  display: flex;
}
.change-user-type-text {
  text-decoration: none;
  color: #8d9298;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
input.change-user-switch {
  position: relative;
  appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #d0dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #fff;
  transition-duration: 200ms;
  margin-left: auto;
  margin-right: auto;
}
input.change-user-switch::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}
input.change-user-switch:checked {
  border-color: #827aff;
  box-shadow: inset 20px 0 0 0 #827aff;
}
input.change-user-switch:checked::after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style: none;
  height: 60px;
  position: relative;
}
.nav-text:hover {
  background-color: #f6f6f7;
  width: 98%;
}
.nav-text:hover .app-count-temp {
  margin-right: 9px;
}
.nav-text:hover .app-count {
  margin-right: 9px;
}
.nav-text:hover img {
  filter: invert(51%) sepia(7%) saturate(245%) hue-rotate(169deg)
    brightness(88%) contrast(90%);
}
/* .move-left{
    transform: translateX(-10px);
  } */
/*  .nav-text:hover .vikariat-copy{
    filter: invert(38%) sepia(2%) saturate(60%) hue-rotate(202deg) brightness(111%) contrast(97%);
  } */
.vikariat-copy {
  filter: invert(62%) sepia(7%) saturate(286%) hue-rotate(172deg)
    brightness(92%) contrast(84%);
}
.nav-text a {
  text-decoration: none;
  color: #8d9298;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  border-radius: 4px;
}
.nav-text a:hover {
  color: #717579;
  font-weight: 600;
}

.nav-text-active {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style: none;
  height: 60px;
  position: relative;
}
.nav-text-active a {
  text-decoration: none;
  color: #555aa1;
  font-weight: 600;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  border-radius: 4px;
}
.nav-text-active g {
  fill: #555aa1;
}

.nav-logo {
  display: flex;
  justify-content: start;
  align-items: center;
  list-style: none;
  height: 120px;
  font-size: 25px;
  margin-left: 1.5rem;
}

.nav-logo a {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.app-count {
  background-color: rgb(238, 67, 67);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: auto;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-right: 15px;
}
.app-count p {
  color: white;
  font-weight: bold;
  margin: auto;
}

.app-count-temp {
  background-color: rgb(238, 67, 67);
  width: 24px;
  height: 24px;
  font-size: 14px;
  border-radius: 50%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-right: 15px;
}
.app-count-temp p {
  color: white;
  font-weight: bold;
  margin: auto;
}

.active-count {
  margin-right: 10px;
}

.vikariat-svg {
  /*  filter: brightness(0) invert(1); */
  width: auto;
  height: 20px;
}

.button-vikariat-svg {
  /* filter: invert(38%) sepia(9%) saturate(3740%) hue-rotate(198deg) brightness(87%) contrast(80%); */
  /* filter: brightness(0) invert(1); */
  width: auto;
  height: 24px;
}

.active-svg {
  filter: invert(38%) sepia(9%) saturate(3740%) hue-rotate(198deg)
    brightness(87%) contrast(80%);
}

.active-page {
  font-weight: bold;
  height: 45px;
  width: 6px;
  background-color: #555aa1;
  border-radius: 6px 6px;
  margin-left: auto;
  padding: 0;
}
.active-page .nav-text a {
  color: #555aa1;
}

.nav-menu-items {
  /* width: 100%; */
  padding: 0;
}
.active-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  /* margin-left: 16px; */
}

.dd-icon {
  width: 16px;
  height: auto;
}
.dropdown-menu {
  width: 100%;
  border: 4px solid rgb(236, 237, 242);
  border-radius: 20px;
  box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
}
.dd-item {
  color: #8d9298;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dd-item:hover {
  color: #555aa1;
  font-weight: bold;
}
.dd-item:active {
  background-color: #555aa1;
}
.dd-item:active img {
  filter: brightness(0) invert(1);
}
.dd-item:hover a {
  color: #555aa1;
}
.dd-item a {
  color: #8d9298;
  text-decoration: none;
}
.dd-item a:hover {
  color: #555aa1;
  text-decoration: none;
}

.slide-container {
  max-height: 52px;
  max-width: 50px;
  overflow: hidden;
}

.slide-container img {
  width: auto;
  height: 100%;
}

.full-container {
  max-width: 219px;
  position: relative;
  float: left;
  height: 100%;
  overflow: hidden;
  -webkit-animation: reveal 850ms ease;
  -webkit-animation-fill-mode: backwards;
}

.full-container .overlay {
  width: 100%;
  height: 100%;
  background: #6e8eea;
  position: absolute;
  transform: scaleX(0);
  transform-origin: 0% 50%;
  animation: anim 850ms;
}

.text-anim {
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 45px;
}

.icon {
  width: 25px;
  filter: invert(80%) sepia(20%) saturate(192%) hue-rotate(189deg)
    brightness(110%) contrast(84%);
}

/* mobile styling below */
.mobile-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5rem;
  border: none;
  /* box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.10); */
  filter: drop-shadow(0px 0px 24px rgba(13, 17, 76, 0.1));
  background-color: #ffffff;
  display: flex;
  /*  overflow-x: auto; */
  z-index: 1000;
  transition: all ease 1s;
}

.mobile-nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 33.33%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: bold;
  z-index: 10;
  color: #8d9298 !important;
  text-decoration: none !important;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}
.mobile-nav-link:focus {
  text-decoration: none;
  color: #555aa1;
}
.mobile-nav-link:hover :focus {
  background-color: #eeeeee;
  text-decoration: none;
  color: #555aa1;
}
.mobile-nav-text {
  height: 1rem;
  text-align: center;
  font-weight: bold;
}
.mobile-icons {
  font-size: 1.4em;
  height: 2rem;
}
.og-color {
  filter: invert(92%) sepia(6%) saturate(621%) hue-rotate(199deg)
    brightness(94%) contrast(89%) !important;
}

.mobile-active {
  color: #555aa1 !important;
}

.larger-icon {
  width: 30px;
  transform: translateX(-6px);
}
.svg-color {
  filter: invert(62%) sepia(6%) saturate(621%) hue-rotate(199deg)
    brightness(94%) contrast(89%);
}
.applied-svg {
  width: 23px;
}
.applied-icon {
  width: 25px;
  position: absolute;
  left: 36px;
}
.applied-mobile {
  width: 23px;
}
.vikariat-icon {
  width: 30px;
  position: absolute;
}
.vikariat-mobile {
  width: 28px;
}
.teacher-icon {
  width: 28px;
  position: absolute;
}
.user-vikariat-svg {
  width: 29px;
}
.settings-svg {
  width: 27px;
}
/*  .nav-text:hover .user-vikariat-svg{
    filter: invert(62%) sepia(6%) saturate(621%) hue-rotate(199deg) brightness(94%) contrast(89%); 
  }
  .nav-text:hover .applied-svg{
    filter: invert(62%) sepia(6%) saturate(621%) hue-rotate(199deg) brightness(94%) contrast(89%);
  } */

.teacher-m-icons {
  /* filter: invert(62%) sepia(6%) saturate(621%) hue-rotate(199deg) brightness(94%) contrast(89%);  */
  width: 27px;
}

.mobile-app-count {
  position: absolute;
  top: 12%;
  left: 17%;
  background-color: rgb(238, 67, 67);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}
.mobile-app-count p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 0.8em;
}

.mobile-chosen-count {
  position: absolute;
  top: 12%;
  left: 50%;
  background-color: #35b85a;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}
.mobile-chosen-count p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 0.8em;
}

/* CSS for ball that shows user to check profile */
.mobile-check-profile {
  position: absolute;
  top: 12%;
  left: 85%;
  background-color: #ff6175 /* rgb(238, 67, 67) */;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}
.mobile-check-profile p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 1em;
}

.other-check-profile {
  position: absolute;
  top: 10%;
  left: 90%;
  background-color: #ff6175 /* rgb(238, 67, 67) */;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}

.other-check-profile p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 1em;
}

.check-school {
  background-color: #ff6175 /* rgb(238, 67, 67) */;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.check-school p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 1em;
}

.dditem-check-profile {
  position: absolute;
  top: 17%;
  left: 75%;
  background-color: #ff6175 /* rgb(238, 67, 67) */;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: text-reveal 850ms;
  -webkit-animation: text-reveal 850ms;
  margin-left: 16px;
}
.dditem-check-profile p {
  color: white;
  font-weight: 600;
  margin: auto;
  font-size: 1em;
}

.circle-container {
  /* width: 80px;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    margin-left: -10%;
    top: -30%;
    overflow: auto;
    z-index: -2; */
  border-radius: 50%;
  background-color: #ffffff;
  z-index: -2;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}
/* .circle-container::before{
    content: '';
    position: absolute;
    width: 150%;
    height: 50%;
    left: -25%;
    top: -10px;
    background: #ffffff;
  }
  .circle-container::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #ffffff;
    border-radius: 50%;
  }
 */
.add-icon {
  width: 60px;
  /* width: 35px; */
}
.active-add-svg {
  filter: invert(58%) sepia(30%) saturate(641%) hue-rotate(210deg)
    brightness(80%) contrast(89%);
}
.middle-btn {
  position: relative;
  top: -45%;
  height: 100px;
}

.nav-school-name {
  position: relative;
  text-align: center;
  list-style: none;
}
.nav-school-text {
  font-size: 1.8em;
  color: #555aa1;
  font-weight: 700;
  animation: text-reveal 1050ms;
  -webkit-animation: text-reveal 1050ms;
  text-decoration: underline;
}

/* animations and media query */
@-webkit-keyframes reveal {
  0% {
    width: 50px;
  }
  100% {
    width: 100%;
  }
}
@keyframes reveal {
  0% {
    width: 50px;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes text-reveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text-reveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .navbar {
    background-color: #ffffff;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: left;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    padding-left: 0rem;
  }
}
