.offline {
  display: flex;
  font-size: large;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.offline button {
  margin-top: 2rem;
  height: 4rem;
  width: 20rem;
  font-size: large;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #555aa1;
  border: none;
  color: white;
  border-radius: 10px;
}
.offline button:hover {
  background-color: rgba(85, 90, 161, 0.75);
}
