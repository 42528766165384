.cBold{
    font-weight: 600;
}
.cSemiBold{
    font-weight: 500;
}
.cReg{
    font-weight: 400;
}
.cBolder{
    font-weight: 900;
}

/*t = text followed by size, small=sm, large=lg etc.*/
.t-sm{
    font-size: 1.2em;
}
.t-md{
    font-size: 1.4em;
}
.t-lg{
    font-size: 1.6em;
}

/* Text Color */
.tc-dark{
    color: #2e2f31;
}
.tc-gray{
    color: #707070;
}