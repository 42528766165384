.mobile-h {
    padding: 0;
    margin: 0;
    height: 100%;
    /* display: flex;
    flex-direction: column; */
}

/* .mobile-h > *{
    flex-shrink: 0;
} */
.mobile-header-image {
    /* z-index: 1; */
    /* width: 100%; */
    /* height: auto;
    margin: auto; */
    /* position: absolute; */
}

.verified-icon-backbar {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
}

.mobile-l {
    width: 50%;
    height: auto;
    position: relative;
    top: 26%;
    /* z-index: 2; */
}

.logo-anim {
    animation: logo-reveal 1s;
    -webkit-animation: logo-reveal 1s;
}

.avatar {
    width: 18%;
    height: auto;
    position: absolute;
    top: 60%;
    left: 41%;
    border-radius: 20px;
}

.m-header {
    position: absolute;
    background-image: url("../../images/mobile_header.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 80vw;
    text-align: center;
    transition: 1s ease;
    width: 100%;
}

.m-header-scroll {
    position: absolute;
    background-image: url("../../images/mobile_header.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 0vw;
    text-align: center;
    transition: 1s ease;
}

.m-cards {
    /* transform: translateY(-14vw); */
    padding-bottom: 80px;
    padding-top: 80vw;
    height: 100%;
}

.m-cards-lessPad {
    padding-bottom: 80px;
    padding-top: 61vw;
    height: 100%;
}

.m-cards-scroll {
    transform: translateY(-41vw);
    padding-bottom: 1rem;
}

.header-text {
    color: white;
    font-size: 1.8em;
    position: absolute;
    width: 100%;
    height: auto;
    top: 85%;
    font-weight: 500;
}

.mobile-header-svg {
    transform: translateY(-1px);
    width: 23px;
    filter: brightness(0) invert(1);
}

.mobile-header-svg-applied {
    transform: translateY(-3px);
    width: 21px;
    filter: brightness(0) invert(1);
}

.mobile-settings-svg {
    width: 23px;
    filter: brightness(0) invert(1);
}

.mobile-header-svg-other {
    transform: translateY(-1px);
    width: 30px;
    filter: brightness(0) invert(1);
}

.mobile-header-svg-more {
    transform: rotate(90deg);
    width: 6px;
    filter: brightness(0) invert(1);
}

.mobile-image-container {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

/* .m-image{
    margin-top: 10px!important;
    transform: translate(-5px);
} */
.teacher-svg-size {
    width: 21px;
}

.h-up {
    top: -100%;
}

.chosen {
    background-color: #35b85a;
    color: white !important;
}

.substitute-unavailable {
    opacity: 0.4 !important;
    cursor: not-allowed;

    flex: 1;
    flex-direction: row;
    border-radius: 14px;
    box-shadow: 0px 0px 24px 0px rgba(13, 17, 77, 0.1);
    outline: none;
    border: none;
    overflow: hidden;
    text-decoration: none;
    color: black;
    max-height: 108px;
    min-height: 108px;;
}

.not-chosen {
    background-color: #ff6175;
    color: white !important;
}

.reg {
    color: #2e2f31;
}

.swipe-delete-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    background-color: #ff6175;
    border-radius: 14px;
    height: 10em;
    padding-left: 0;
    min-width: 9em;
}

.swipe-restore-container {
    background-color: #35b85a;
    border-radius: 14px;
    height: 10em;
    padding-left: 0;
    min-width: 9em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
}

.swipe-inactive-container {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    background-color: #35b85a;
    border-radius: 14px;
    height: 10em;
    padding-left: 0;
    min-width: 9em;
}

.swipe-text {
    color: white;
    padding: 0;
    margin: 0;
}

.swipe-icon {
    width: 4em;
    transform: scale(-1, 1);
}

.swipe-icon-dots {
    filter: brightness(0) invert(1);
    width: .7em;
    transform: rotate(90deg);
}

.swipe-trash-icon {
    width: 2.5em;
}

.inactive-title {
    padding-top: 1em;
    font-size: 1.8em;
    margin-left: 1em;
    color: white;
    font-weight: 500;
    /*  z-index: -1; */
}

.inactive-section {
    /*  background-color: rgba(0, 2, 22, 0.568); */
    background-color: rgba(39, 47, 112, 0.863);
    /* background-color: rgba(39, 47, 112);
    opacity: 50%;
    z-index: 99; */
    /* min-height: 25vh; */
    /* flex-grow: 1; */
}

.hide-section {
    display: none;
}

.sticky-backbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    border-radius: 14px;
}

.backbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    align-self: flex-start;
}

@-webkit-keyframes logo-reveal {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes logo-reveal {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 600px) {
    .mobile-margin {
        margin-top: 32vh;
    }
}