.reg-body{
    background-color: #F6F6F7;
    height: 100vh;
    display: flex;
    width: 100%;
    align-items: stretch;
}

@media (max-width: 600px){
    .mobile-style{
        max-width: 100%;
        max-height: 100%;
    }
}