.app {
  display: flex;
  position: relative;
}
/* 
.ios-head{
  display: none;
} */

/* small text */
.st {
  font-size: 1.2em;
}

.custom-template {
  background-color: #555aa1;
  width: 100%;
}
/* wrapper for spinner */
.spin-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #f6f6f7;
}
/* Animations */
.fadein {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinning {
  position: absolute;
  width: 80px;
  height: 80px;
  /* margin: -75px 0 0 -75px; */
  top: 50%;
  left: 50%;
  animation: spin 1.5s infinite linear;
}

.confirm {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #040841;
}
/*  */
.confirm-email-splash {
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.spinning svg {
  border-radius: 50%;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(
      0deg
    ); /* 
      -ms-transform: rotate(0deg);
      transform: rotate(0deg); */
  }
  100% {
    -webkit-transform: rotate(
      360deg
    ); /* 
      -ms-transform: rotate(360deg);
      transform: rotate(360deg); */
  }
}

/*   @media (max-width: 600px){
    .ios-head{
      background-color: red;
      z-index: 99;
      position: fixed;
      height: 50px;
      width: 100%;
      top: -40px;
      display: initial;
    }
  } */
